import { memo, useEffect } from 'react';
import { useTicket, useTimeHandler } from '@tymely/services';

import { Body } from './TicketLayout/Main';

export * from './Arguments';

export const Ticket = memo(() => {
    const ticket = useTicket();
    const timer = useTimeHandler();

    useEffect(() => {
        if (ticket) {
            timer.reset();
        }
    }, [ticket.id]);

    if (!ticket) {
        throw new Error('Ticket not loaded');
    }

    return <Body />;
});

Ticket.displayName = 'Ticket';
