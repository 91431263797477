import React from 'react';
import {
    applyArgUpdates,
    getVisibleArgs,
    IArgument,
    IArgumentUpdate,
    IDineshTicketOperations,
    isCategorical,
    isFalsy,
    isPolicyEvaluated,
    isPolicyEvaluationRunning,
    isTextArgument,
    wereArgsChanged,
} from '@tymely/atoms';
import {
    AppMode,
    IWaitType,
    useAgentResponse,
    useAppMode,
    useCreateTicketCrumb,
    useDecisionQuery,
    useEditArgumentMutation,
    useEvaluatePolicy,
    useEventSubscription,
    useSelectedComment,
    useSetAlert,
    useTicket,
    useTicketUserWaited,
} from '@tymely/services';
import { useRecoilState } from 'recoil';
import { useKeys } from '@tymely/utils';
import { Key } from 'ts-key-enum';
import debounce from 'lodash/debounce';

import { isHighConfidenceArgument } from './utils';
import useArgumentsQuery from './useArgumentsQuery';

const getFindAccessoryArg = (updatedArg: IArgument) => (arg: IArgument) => {
    if (!isCategorical(arg)) {
        return false;
    }
    if ([arg.search_md_id, arg.regulator_md_id].includes(updatedArg.md_id)) {
        return true;
    }
    if (arg.lazy && arg.group_by === updatedArg.name) {
        return true;
    }
    return false;
};

type ArgumentsTabsContext = {
    argsLastUpdate?: Date;
    version?: string;
    approvedArgs?: Set<IArgument['md_id']>;
    onHighlightText?: (text: string) => void;
    onEvaluatePolicy: () => Promise<void>;
    textArgs: IArgument[];
    updates: IArgumentUpdate[];
    onUpdate: (newUpdates: IArgumentUpdate[], isApprove?: boolean) => void;
};

const argumentsTabsContext = React.createContext<ArgumentsTabsContext | undefined>(undefined);

const _Provider = argumentsTabsContext.Provider;

export type ArgumentsTabsProviderProps = React.PropsWithChildren<
    Pick<ArgumentsTabsContext, 'version' | 'onHighlightText' | 'argsLastUpdate'>
> & {
    onEditStatus: (value?: string) => void;
};

export type ArgumentsTabsProviderRef = {
    reset: () => void;
};

const useCommentSubscription = (version?: string) => {
    const argsQuery = useArgumentsQuery({ version });
    const setAlert = useSetAlert();
    const comment = useSelectedComment();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setPolicyEvaluated] = useRecoilState(isPolicyEvaluated);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [__, setPolicyEvaluationRunning] = useRecoilState(isPolicyEvaluationRunning);
    const agentResponseQuery = useAgentResponse();
    const decisionQuery = useDecisionQuery(!version);

    useEventSubscription(
        { channel: `comment/${comment?.id}`, objectTypes: ['Decision', 'Error'] },
        async (event) => {
            setPolicyEvaluationRunning(false);
            setPolicyEvaluated(true);
            if (event.data && event.data.error) {
                setAlert(event.data.error as string, 'error');
                return;
            }
            await Promise.all([decisionQuery.refetch(), agentResponseQuery.refetch(), argsQuery.refetch()]);
        },
        () => setPolicyEvaluationRunning(false),
        [comment?.id],
    );
};

const useTicketSubscription = (version?: string) => {
    const argsQuery = useArgumentsQuery({ version });
    const debouncedRefetchArguments = React.useCallback(
        debounce(() => {
            argsQuery.refetch().then((d) => d);
        }, 1000),
        [],
    );

    const ticket = useTicket();

    useEventSubscription({ channel: `ticket/${ticket.id}`, objectTypes: ['Argument'] }, debouncedRefetchArguments);
};

const ArgumentsTabsProvider = React.forwardRef<ArgumentsTabsProviderRef, ArgumentsTabsProviderProps>(
    ({ version, argsLastUpdate, onHighlightText, onEditStatus, children }, ref) => {
        const createTicketCrumb = useCreateTicketCrumb();
        const comment = useSelectedComment();
        const setAlert = useSetAlert();
        const { appMode } = useAppMode();
        const [textArgs, setTextArgs] = React.useState<IArgument[]>([]);
        const [approvedArgs, setApprovedArgs] = React.useState<Set<IArgument['md_id']>>(new Set());
        const [updates, setUpdates] = React.useState<IArgumentUpdate[]>([]);
        const { waitFor } = useTicketUserWaited();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, setPolicyEvaluated] = useRecoilState(isPolicyEvaluated);

        const editMutation = useEditArgumentMutation({
            onSuccess: () => {
                setUpdates([]);
                onEditStatus(undefined);
            },
            onStatus: onEditStatus,
            onError: () => onEditStatus(undefined),
        });

        const argsQuery = useArgumentsQuery({
            version,
            onSuccess: () => {
                createTicketCrumb(IDineshTicketOperations.USER_GOT_UPDATED_ARGUMENTS);
            },
            onError: () => setAlert(' There was an error while fetching arguments', 'error'),
        });

        React.useEffect(() => {
            const args = argsQuery.data;
            const newTextArgs = getVisibleArgs(args.filter(isTextArgument));
            setTextArgs(newTextArgs);
            setApprovedArgs(
                (approved) =>
                    new Set([
                        ...approved,
                        ...(newTextArgs ?? [])
                            .filter((arg) => isHighConfidenceArgument(arg) || arg.is_edited)
                            .map((arg) => arg.md_id),
                    ]),
            );
        }, [argsQuery.data]);

        const evaluatePolicyMutation = useEvaluatePolicy({
            onError: () => setAlert('There was an error while evaluating policy', 'error'),
        });

        const onEvaluatePolicy = React.useCallback(async () => {
            createTicketCrumb(IDineshTicketOperations.USER_CLICKED_OK);
            if (!version && updates.length) {
                await editMutation.mutateAsync(updates);
            } else if (comment) {
                await evaluatePolicyMutation.mutateAsync({ commentId: comment.id, runAsync: true });
            }
        }, [version, updates, comment]);

        const onUpdate = React.useCallback(
            (newUpdates: IArgumentUpdate[], isApprove = false) => {
                if (!argsQuery.data) {
                    return;
                }
                if (version) {
                    // Update locally if it's historical args.
                    argsQuery.updateArguments(applyArgUpdates(argsQuery.data, newUpdates));
                    return;
                }
                const _updates = updates
                    .filter((_update) => !newUpdates.find((newUpdate) => newUpdate.id === _update.id))
                    .filter((update) => argsQuery.data.find((arg) => arg.id === update.id))
                    .concat(newUpdates);

                const updatedArg = _updates.find((update) => {
                    const updatedArg = argsQuery.data.find((arg) => arg.id === update.id);
                    if (!updatedArg) {
                        return false;
                    }
                    const sysArgUpdated = updatedArg.arg_type === 'SYSTEM_ARGUMENT';
                    const accessoryArgUpdated = argsQuery.data.find(getFindAccessoryArg(updatedArg));
                    return sysArgUpdated || accessoryArgUpdated;
                });

                if (updatedArg && !isApprove) {
                    waitFor(
                        IWaitType.POLICY_EVALUATION,
                        editMutation.mutateAsync(_updates).then(() => argsQuery.refetch()),
                    );
                }

                if (textArgs) {
                    const _textArgs = getVisibleArgs(applyArgUpdates(textArgs, _updates));

                    const approvedArgsUpdated = new Set(
                        [
                            ...approvedArgs,
                            ..._updates
                                .map((update) => argsQuery.data.filter((arg) => arg.id === update.id)[0])
                                .map((arg) => arg.md_id),
                        ].filter((md_id) => {
                            const arg = _textArgs?.find((arg) => arg.md_id === md_id);
                            return arg && !isFalsy(arg);
                        }),
                    );
                    setApprovedArgs(approvedArgsUpdated);

                    const lastUpdatedArg = _textArgs.find((arg) => _updates.find((update) => update.id === arg.id));
                    if (
                        appMode === AppMode.QA ||
                        (lastUpdatedArg &&
                            !_textArgs
                                .filter((arg) => arg.rank === lastUpdatedArg.rank)
                                .some((arg) => isFalsy(arg) || !approvedArgsUpdated.has(arg.md_id)))
                    ) {
                        if (
                            (argsQuery.data && wereArgsChanged(_textArgs, argsQuery.data)) || // some arg value change (not just approval update)
                            !_textArgs.some((arg) => !approvedArgsUpdated.has(arg.md_id)) // all approved
                        ) {
                            waitFor(IWaitType.POLICY_EVALUATION, editMutation.mutateAsync(_updates));
                        }
                    }
                    setTextArgs(_textArgs);
                }
                setUpdates(_updates);
                setPolicyEvaluated(false);
            },
            [argsQuery.data, updates, textArgs, argsQuery.updateArguments, version, appMode],
        );

        useTicketSubscription(version);

        useKeys([Key.Control, Key.Alt, 'KeyO'], onEvaluatePolicy);
        useKeys([Key.Control, Key.Alt, 'KeyG'], () => setPolicyEvaluated(true));

        React.useImperativeHandle(ref, () => ({
            reset: () => {
                onUpdate(textArgs?.map((arg) => ({ id: arg.id, value: null, special_value: 'reset' })) ?? []);
                setApprovedArgs(new Set());
            },
        }));

        useCommentSubscription(version);

        return (
            <_Provider
                value={{
                    version,
                    textArgs,
                    approvedArgs,
                    onHighlightText,
                    onEvaluatePolicy,
                    updates,
                    argsLastUpdate,
                    onUpdate,
                }}
            >
                {children}
            </_Provider>
        );
    },
);

ArgumentsTabsProvider.displayName = 'ArgumentsTabsProvider';

export const useArgumentsTabsContext = () => {
    const context = React.useContext(argumentsTabsContext);
    if (!context) {
        throw Error("'useArgumentsTabsContext' cannot be used outside of 'ArgumentsTabsProvider'");
    }
    return context;
};

export default ArgumentsTabsProvider;
