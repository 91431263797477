import axios from 'axios';
import {
    IArgumentMetadata,
    ArgMetadatadInUseDetails,
    dType,
    IArgumentUpdate,
    IArgumentExtractorInfo,
    dTypeSchema,
} from '@tymely/atoms';

export const editArguments = (
    updateData: IArgumentUpdate[],
    commentId: number,
    updateArgumentTree: boolean,
    run_async: boolean,
) =>
    axios
        .put(`arguments/edit?comment_id=${commentId}&async=${run_async}`, {
            update_data: updateData,
            update_argument_tree: updateArgumentTree,
        })
        .then((resp) => resp.data);

export const editArgument = (argId: number) => axios.put(`argument/${argId}/approve`).then((resp) => resp.data);

export const getArgumentMetadata = (id: number) =>
    axios.get(`argument-metadata/${id}`).then((resp) => resp.data as IArgumentMetadata);

export const listArgumentsMetadata = () =>
    axios
        .get<{ results: IArgumentMetadata[] }>('argument-metadatas/list', {
            params: { limit: 2000 },
        })
        .then((res) => res.data?.results);

export const createArgumentMetadata = (metadata: Omit<IArgumentMetadata, 'id'>) =>
    axios.post<IArgumentMetadata>('argument-metadata', metadata).then((resp) => resp.data);

export const editArgumentMetadata = (metadata: IArgumentMetadata) =>
    axios.put<IArgumentMetadata>(`argument-metadata/${metadata.id}`, metadata).then((resp) => resp.data);

export const deleteArgumentMetadata = (metadata: IArgumentMetadata) => axios.delete(`argument-metadata/${metadata.id}`);

export const getArgsUsage = (argNames: IArgumentMetadata['name'][]) =>
    axios
        .get<
            Record<IArgumentMetadata['name'], ArgMetadatadInUseDetails>
        >(`arg-usage?${argNames.map((a) => `arg_name=${a}`).join('&')}`)
        .then((resp) => resp.data);

export const listArgExtractors = () =>
    axios.get<IArgumentExtractorInfo[]>('argument-extractors').then((res) => res.data);

export const getArgExtractorDTypes = () => axios.get<dType[]>('schema/dtypes').then((res) => res.data);

export const getDTypeSchema = (dtype: string) =>
    axios.get<dTypeSchema>(`schema/dtype/${dtype}`).then((res) => res.data);
