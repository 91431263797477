import React, { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import { UseMutationOptions, UseQueryOptions } from 'react-query/types/react/types';
import {
    ApiError,
    AppMode,
    ArgumentUsedError,
    createArgumentMetadata,
    deleteArgumentMetadata,
    editArgumentMetadata,
    getArgExtractorDTypes,
    getArgsUsage,
    getDTypeSchema,
    getCommentArguments,
    getCommentArgumentVersions,
    listArgExtractors,
    listArgumentsMetadata,
    NoPolicyError,
    postEvaluatePolicy,
    editArguments,
    editArgument,
} from '@tymely/api';
import {
    ArgMdActionUsageDetails,
    ArgMetadatadInUseDetails,
    ArgMetadataUsage,
    IArgument,
    IArgumentMetadata,
    IArgumentExtractorInfo,
    dType,
    dList,
    IArgumentUpdate,
    sortArgs,
    isPolicyEvaluated,
} from '@tymely/atoms';
import { useSetRecoilState } from 'recoil';

import { useSetAlert } from './alerts.services';
import {
    useAgentResponse,
    useDecisionQuery,
    useSelectedComment,
    useSetHistoricAnalysis,
    DECISION_QUERY_KEY,
    AGENT_RESPONSE_QUERY_KEY,
    ARGUMENTS_QUERY_KEY,
} from './comment.services';
import { useAppMode } from './mode';
import { useFeatureFlags } from './feature.services';

const ARGUMENTS_METADATA_QUERY_KEY = 'argumentsMetadata';
const ARGUMENT_EXTRACTORS_QUERY_KEY = 'argExtractorNames';
const ARGUMENT_DTYPE_HINTS_QUERY_KEY = 'argExtractorDTypes';

interface EditArgumentByCommentIdParams {
    argUpdatesByMetadataId: IArgumentUpdate[];
    commentId: number;
    run_async: boolean;
}

interface EditArgumentByCommentIdResults {
    didEditArguments?: boolean;
    missingMetadataIds: number[];
}

export const useEditArgumentByCommentIdMutation = (opts: {
    onSuccess?: (args: EditArgumentByCommentIdResults) => void;
    onError?: (message?: string) => void;
}) => {
    const setAlert = useSetAlert();
    const queryClient = useQueryClient();
    const { appMode } = useAppMode();

    return useMutation<EditArgumentByCommentIdResults, AxiosError, EditArgumentByCommentIdParams>(
        async ({ argUpdatesByMetadataId, commentId, run_async }) => {
            const commentArguments = await getCommentArguments(commentId);
            const commentArgumentIdByMetadataId = new Map(commentArguments.map((arg) => [arg.md_id, arg.id]));
            const missingMetadataIds: number[] = [];
            const argUpdates = argUpdatesByMetadataId
                .map((argUpdateByMetadataId) => {
                    const argId = commentArgumentIdByMetadataId.get(argUpdateByMetadataId.id);
                    if (argId === undefined) {
                        missingMetadataIds.push(argUpdateByMetadataId.id);
                        return undefined;
                    }
                    return {
                        ...argUpdateByMetadataId,
                        id: argId,
                    };
                })
                .filter(Boolean);

            if (argUpdates.length > 0) {
                await editArguments(argUpdates, commentId, appMode !== AppMode.QA, run_async);
            }

            return {
                didEditArguments: argUpdates.length > 0,
                missingMetadataIds,
            };
        },
        {
            mutationKey: 'changeArgument',
            onSuccess: async (data, { commentId }) => {
                if (appMode !== AppMode.QA) {
                    await Promise.all([
                        queryClient.invalidateQueries([DECISION_QUERY_KEY, commentId]),
                        queryClient.invalidateQueries([AGENT_RESPONSE_QUERY_KEY, commentId]),
                    ]);
                }
                return opts.onSuccess?.(data);
            },
            onError: (error, { commentId, argUpdatesByMetadataId }) => {
                opts.onError?.(error.message);
                setAlert(
                    error.message,
                    'error',
                    5000,
                    `Failed editing comment ${commentId} argument metadatas (${argUpdatesByMetadataId
                        .map((v) => v.id)
                        .join(', ')}).`,
                );
            },
            retry: 1,
        },
    );
};

export const useEditArgumentMutation = (opts: {
    onSuccess?: () => void;
    onStatus: (status: string) => void;
    onError?: (message?: string) => void;
}) => {
    const setPolicyEvaluated = useSetRecoilState(isPolicyEvaluated);
    const setAlert = useSetAlert();
    const selectedComment = useSelectedComment();
    const queryClient = useQueryClient();
    const { appMode } = useAppMode();
    const { getFeatureFlagValue } = useFeatureFlags();

    useArgumentsQuery({
        commentId: selectedComment?.id,
        enabled: !!selectedComment?.selected_intent_id,
    });

    return useMutation<IArgument[], AxiosError, IArgumentUpdate[]>(
        (params) => {
            if (!selectedComment) {
                return Promise.reject('Comment ID is not defined.');
            }

            return editArguments(
                params,
                selectedComment.id,
                appMode !== AppMode.QA,
                getFeatureFlagValue('async-argument-editing'),
            );
        },
        {
            mutationKey: 'changeArgument',
            onMutate: () => opts.onStatus('Updating arguments.'),
            onSuccess: () => {
                setPolicyEvaluated(true);
                if (!selectedComment) {
                    return;
                }
                opts.onStatus('Calculating policy.');
                const queryInvalidation =
                    appMode !== AppMode.QA
                        ? [
                              queryClient.invalidateQueries([DECISION_QUERY_KEY, selectedComment.id]),
                              queryClient.invalidateQueries([AGENT_RESPONSE_QUERY_KEY, selectedComment.id]),
                          ]
                        : [];
                return Promise.all(queryInvalidation).then(() => opts.onSuccess?.());
            },
            onError: (error, variables) => {
                opts.onError?.(error.message);
                setAlert(
                    error.message,
                    'error',
                    5000,
                    `Failed editing arguments (${variables.map((v) => v.id).join(', ')}).`,
                );
            },
        },
    );
};

export const useArgumentsMetadataQuery = (onSuccess?: UseQueryOptions<IArgumentMetadata[]>['onSuccess']) => {
    const setAlert = useSetAlert();

    return useQuery<IArgumentMetadata[]>(ARGUMENTS_METADATA_QUERY_KEY, listArgumentsMetadata, {
        onSuccess,
        onError: () => {
            setAlert('failed fetching arguments metadata', 'error');
        },
    });
};

export const useArgExtractorsQuery = (onSuccess?: UseQueryOptions<IArgumentExtractorInfo[]>['onSuccess']) => {
    const setAlert = useSetAlert();

    return useQuery<IArgumentExtractorInfo[]>(ARGUMENT_EXTRACTORS_QUERY_KEY, listArgExtractors, {
        onSuccess,
        onError: () => {
            setAlert('failed fetching argument extractor names', 'error');
        },
        staleTime: Infinity,
    });
};

export const useDTypeHintsQuery = (onSuccess?: UseQueryOptions<(dType | dList<dType>)[]>['onSuccess']) => {
    return useQuery(
        ARGUMENT_DTYPE_HINTS_QUERY_KEY,
        () => {
            return getArgExtractorDTypes().then((types) => [...types, ...types.map((type) => `list[${type}]`)]);
        },
        {
            onSuccess,
            staleTime: Infinity,
        },
    );
};

export const useCreateArgumentMetadataMutation = (onSuccess?: (arg: IArgumentMetadata) => void) => {
    const setAlert = useSetAlert();
    const queryClient = useQueryClient();

    return useMutation<IArgumentMetadata, AxiosError, { metadata: Omit<IArgumentMetadata, 'id'> }>(
        'create-arg-metadata',
        (params) => createArgumentMetadata(params.metadata),
        {
            onSuccess: (data) => {
                if (!data.deleted_at) {
                    queryClient.setQueriesData(ARGUMENTS_METADATA_QUERY_KEY, (cache?: IArgumentMetadata[]) =>
                        cache ? cache.concat(data) : [data],
                    );
                }
                onSuccess?.(data);
            },
            onError: (_, variables) => {
                setAlert(`Failed creating argument "${variables.metadata.name}"`, 'error');
            },
        },
    );
};

function formatMissingArgDetailsItem(details: ArgMetadataUsage | ArgMdActionUsageDetails) {
    const detailsStr = `&nbsp;&nbsp;org_id=${details.organization_id}, policy_set_id=${details.policy_set_id}, workflow_id=${details.workflow_id}`;
    return 'path' in details && details.path ? `${detailsStr}, action_arg=${details.path}` : detailsStr;
}

function formatMissingArgDetails(
    title: string,
    details: (ArgMetadataUsage | ArgMdActionUsageDetails)[],
): string | null {
    if (details.length === 0) {
        return null;
    }
    return `<strong>${title}</strong><br/>${details.map(formatMissingArgDetailsItem).join('<br/>')}`;
}

export const useEditArgumentMetadataMutation = (onSuccess?: UseQueryOptions<IArgumentMetadata>['onSuccess']) => {
    const setAlert = useSetAlert();
    const queryClient = useQueryClient();

    return useMutation<IArgumentMetadata, AxiosError, IArgumentMetadata>(editArgumentMetadata, {
        onSuccess: (data) => {
            queryClient.setQueriesData(ARGUMENTS_METADATA_QUERY_KEY, (cache?: IArgumentMetadata[]) =>
                cache ? cache.map((arg) => (arg.id === data.id ? data : arg)) : [data],
            );
            onSuccess?.(data);
        },
        onError: (error: AxiosError, variables) => {
            const errorMessage =
                error instanceof ArgumentUsedError
                    ? '<strong>Argument is being used:</strong><br/>' +
                      [
                          formatMissingArgDetails('Templates', error.detail.templates),
                          formatMissingArgDetails('Workflows', error.detail.policies),
                          formatMissingArgDetails('Action args', error.detail.actions),
                      ]
                          .filter(Boolean)
                          .join('<br/><br/>')
                    : '';

            setAlert(errorMessage, 'error', 0, `Failed saving argument "${variables.name}" (id=${variables.id})`);
        },
    });
};

type UseEditArgumentArgs = UseMutationOptions<unknown, unknown, number>;

export const useEditArgument = (options?: UseEditArgumentArgs) => useMutation('edit-arguments', editArgument, options);

export const useDeleteArgumentMetadataMutation = (onSuccess?: UseQueryOptions<void>['onSuccess']) => {
    const setAlert = useSetAlert();
    const queryClient = useQueryClient();

    return useMutation(deleteArgumentMetadata, {
        onSuccess: (_, input) => {
            queryClient.setQueriesData(ARGUMENTS_METADATA_QUERY_KEY, (cache?: IArgumentMetadata[]) =>
                cache ? cache.filter((arg) => arg.id !== input.id) : [],
            );
            onSuccess?.();
        },
        onError: (_, variables) => {
            setAlert(`Failed deleting argument "${variables.name}"`, 'error');
        },
    });
};

export const usePoliciesUsageQuery = (
    argNamesChunks: IArgumentMetadata['name'][][],
    onSuccess?: (usage: Record<IArgumentMetadata['name'], ArgMetadatadInUseDetails>) => void,
    onError?: (error: AxiosError, argNames: IArgumentMetadata['name'][]) => void,
) => {
    const queries = [];
    for (const argNames of argNamesChunks) {
        queries.push({
            queryKey: ['argsUsage', ...argNames],
            queryFn: () => getArgsUsage(argNames),
            onSuccess,
            onError: (error: AxiosError) => {
                onError?.(error, argNames);
            },
            retry: false,
        });
    }
    useQueries(queries);
};

export const useArgumentVersionsQuery = (options?: UseQueryOptions<string[], AxiosError>) => {
    const selectedComment = useSelectedComment();
    const commentId = selectedComment?.id;
    return useQuery<string[], AxiosError>(
        ['argumentVersions', commentId],
        () => (commentId ? getCommentArgumentVersions(commentId) : Promise.reject([])),
        {
            ...options,
            enabled: !!commentId,
            retry: (_, error) => {
                return !(error instanceof ApiError);
            },
        },
    );
};

export const useArgumentsQuery = (
    opts: {
        commentId?: number;
        argsVersion?: string;
        onNoPolicy?: () => void;
    } & UseQueryOptions<IArgument[], AxiosError>,
) => {
    const enabled = Boolean((opts.enabled ?? true) && opts.commentId);
    const { updateDecision, data: decision } = useDecisionQuery(enabled && !opts.argsVersion);
    const { updateResponse } = useAgentResponse();
    const setHistoricAnalysis = useSetHistoricAnalysis();
    const queryClient = useQueryClient();
    const [isFetching, setIsFetching] = useState(false);

    const updateArguments = useCallback(
        (updatedArgs: IArgument[], replace = false) =>
            queryClient.setQueryData<IArgument[] | undefined>(
                opts.argsVersion ? ['arguments', opts.commentId, opts.argsVersion] : ['arguments', opts.commentId],
                replace
                    ? updatedArgs
                    : (args?: IArgument[]) => args?.map((arg) => updatedArgs.find(({ id }) => id === arg.id) || arg),
            ),
        [queryClient, opts.commentId, opts.argsVersion],
    );

    const query = useQuery<IArgument[], AxiosError | ApiError>(
        opts.argsVersion ? [ARGUMENTS_QUERY_KEY, opts.commentId, opts.argsVersion] : ['arguments', opts.commentId],
        () => {
            if (!opts.commentId) {
                return Promise.reject('Comment ID is not defined.');
            }

            return getCommentArguments(opts.commentId, opts.argsVersion);
        },
        {
            ...opts,
            enabled,
            select: sortArgs,
            onSuccess: (updatedArguments) => {
                opts.onSuccess?.(updatedArguments);
                if (opts.commentId && opts.argsVersion) {
                    setIsFetching(true);
                    postEvaluatePolicy(opts.commentId, updatedArguments)
                        .then((comment) => {
                            if (comment.selected_intent_id) {
                                updateDecision(
                                    comment.additional_data.decisions[comment.selected_intent_id]['decision'],
                                );
                                updateResponse(comment.response_body || '');
                                setHistoricAnalysis(comment);
                            }
                        })
                        .finally(() => {
                            setIsFetching(false);
                        });
                }
            },
            onError(error) {
                setIsFetching(false);
                if (error instanceof NoPolicyError) {
                    opts.onNoPolicy?.();
                } else {
                    opts.onError?.(error);
                }
            },
            retry: false,
            staleTime: Infinity,
        },
    );

    const decisionParticipatingArgs = React.useMemo(() => {
        const lineage = decision?.decision_lineage;

        if (!query.data || !lineage?.length) {
            return query.data ?? [];
        }

        return query.data.filter((arg) => {
            return !!arg.dependent_node_ids?.some((nodeId) => lineage.includes(nodeId));
        });
    }, [decision, query.data]);

    return { ...query, data: decisionParticipatingArgs, updateArguments, isFetching: query.isFetching || isFetching };
};

export const useArgumentSchemaQuery = (dtype?: string) => {
    return useQuery(
        ['dtypeSchema', dtype],
        () => (dtype ? getDTypeSchema(dtype) : Promise.reject('dtype is not provided')),
        {
            enabled: !!dtype,
        },
    );
};
