import React from 'react';
import sortBy from 'lodash/sortBy';
import { TransitionGroup } from 'react-transition-group';
import { Collapse, List, styled, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useCreateTicketCrumb, useSelectedComment, useTicket } from '@tymely/services';
import { IArgument, IArgumentUpdate, IDineshTicketOperations, isCategorical } from '@tymely/atoms';
import { yellow } from '@mui/material/colors';

import { getDisplayRegulatorArg, getGroupArg, getSearchArg } from './utils';
import ArgumentListItem from './ArgumentListItem';
import { useArgumentsTabsContext } from './ArgumentsTabsProvider';
import useArgumentsQuery from './useArgumentsQuery';

const getArgumentFindFunction =
    (arg: IArgument) =>
    ({ md_id, name }: IArgument) => {
        if (!isCategorical(arg)) {
            return false;
        }
        if ([arg.search_md_id, arg.regulator_md_id].includes(md_id)) {
            return true;
        }
        if (arg.lazy && arg.group_by === name) {
            return true;
        }
        return false;
    };

const _List = styled(List)`
    padding: 0;
`;

const _Collapse = styled(Collapse)`
    background: ${yellow[200]};
    &.MuiCollapse-entered {
        background: 0;
    }
    &.MuiCollapse-hidden {
        background: 0;
    }
`;

type ArgumentsProps = {
    argumentItems: IArgument[];
    withCopy?: boolean;
    disabled?: boolean;
    loading?: boolean;
    touchedArgIds?: Set<IArgument['md_id']>;
    approvedArgs?: Set<IArgument['md_id']>;
    onTouch?: (updates: IArgument) => void;
};

const ArgumentList = React.memo(
    ({
        argumentItems,
        withCopy,
        disabled,
        loading,
        touchedArgIds = new Set<IArgument['md_id']>(),
        approvedArgs = new Set<IArgument['md_id']>(),
        onTouch,
    }: ArgumentsProps) => {
        const [argsOrder, setArgsOrder] = React.useState<IArgument['md_id'][]>([]);

        const { version, onUpdate } = useArgumentsTabsContext();
        const argsQuery = useArgumentsQuery({ version });
        const allArgs = argsQuery.data ?? [];

        React.useEffect(() => {
            setArgsOrder((order) =>
                order.concat(
                    sortBy(
                        argumentItems.map((arg) => arg.md_id).filter((md_id) => !order.includes(md_id)),
                        (md_id) => (approvedArgs.has(md_id) ? 0 : 1),
                    ),
                ),
            );
        }, [argumentItems, setArgsOrder]);

        const args = React.useMemo(
            () => sortBy(argumentItems, (arg) => argsOrder.findIndex((md_id) => md_id === arg.md_id)),
            [argumentItems, argsOrder],
        );

        const ticket = useTicket();
        const createTicketCrumb = useCreateTicketCrumb();
        const selectedComment = useSelectedComment();

        const firstUnapprovedArgAt = React.useMemo(() => {
            const unapprovedAt = args.findIndex((arg) => !approvedArgs || !approvedArgs.has(arg.md_id));
            return unapprovedAt >= 0 ? unapprovedAt : args.length;
        }, [args, approvedArgs]);

        const [lastUpdatedArgs, setLastUpdatedArgs] = React.useState<IArgument[]>([]);

        const _onUpdate = React.useCallback(
            (updates: IArgumentUpdate[], isApprove = false) => {
                for (const update of updates) {
                    const arg = allArgs.find((a) => a.id === update.id);
                    if (arg) {
                        const operation = isApprove
                            ? IDineshTicketOperations.USER_APPROVED_ARGUMENT
                            : IDineshTicketOperations.USER_EDITED_TICKET_ARGUMENTS;
                        createTicketCrumb(operation, {
                            ticket_id: ticket?.id ?? null,
                            argument_id: arg.id,
                            argument_name: arg.name,
                            argument_value: update.special_value || update.value,
                            argument_prev_value: arg.value,
                            comment_id: selectedComment?.id ?? null,
                            ticket_intent: selectedComment?.selected_intent_id ?? null,
                        });
                    }
                }
                setLastUpdatedArgs(updates.map((update) => allArgs.find((a) => a.id === update.id)).filter(Boolean));
                onUpdate?.(updates, isApprove);
            },
            [allArgs, createTicketCrumb, selectedComment, onUpdate, setLastUpdatedArgs],
        );

        return (
            <>
                {args.length === 0 && <Typography variant="body2">No items</Typography>}
                {args.length > 0 && (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <_List dense>
                            <TransitionGroup appear={false} exit={false}>
                                {args.map((arg, index, array) => {
                                    const groupArgument = getGroupArg(arg, allArgs);
                                    const searchArgument = getSearchArg(groupArgument ?? arg, allArgs);
                                    const displayRegulatorArgument = getDisplayRegulatorArg(
                                        groupArgument ?? arg,
                                        allArgs,
                                    );
                                    const isLoadingAfterAccessoryArgumentChange =
                                        loading && !!lastUpdatedArgs.find(getArgumentFindFunction(arg));
                                    const isEditable =
                                        (approvedArgs.has(arg.md_id) && index < firstUnapprovedArgAt) ||
                                        index === firstUnapprovedArgAt;
                                    const onItemUpdate = (argUpdate: IArgumentUpdate[], isApprove: boolean) => {
                                        _onUpdate(argUpdate, isApprove);
                                        onTouch?.(arg);
                                    };
                                    const withSeparator = array[index - 1] && array[index - 1].rank !== arg.rank;
                                    const className = onTouch
                                        ? touchedArgIds.has(arg.md_id)
                                            ? 'touched'
                                            : 'untouched'
                                        : undefined;

                                    const withApproval = approvedArgs.size > 0;
                                    const approved = approvedArgs.has(arg.md_id);

                                    return (
                                        <_Collapse key={arg.name} className={className} onClick={() => onTouch?.(arg)}>
                                            <ArgumentListItem
                                                withSeparator={withSeparator}
                                                argument={arg}
                                                groupArgument={groupArgument}
                                                displayRegulatorArgument={displayRegulatorArgument}
                                                searchArgument={searchArgument}
                                                withApproval={withApproval}
                                                approved={approved}
                                                editable={isEditable}
                                                disabled={disabled}
                                                loading={isLoadingAfterAccessoryArgumentChange}
                                                withCopy={withCopy}
                                                onUpdate={onItemUpdate}
                                            />
                                        </_Collapse>
                                    );
                                })}
                            </TransitionGroup>
                        </_List>
                    </LocalizationProvider>
                )}
            </>
        );
    },
);

ArgumentList.displayName = 'ArgumentList';

export default ArgumentList;
